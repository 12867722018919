<template>
	<div>
		<Navbar />
		<Header title="Contacts" excerpt="Take a loot at your saved contacts.">
			<HeaderButton title="Create" excerpt="You can create new contacts" route="/contacts/create" />
			<HeaderButton title="Import" excerpt="You can import new contacts" route="/contacts/import" />
		</Header>

		<!-- CONTENT -->
		<b-container>
			<b-card no-body style="border-color: #e1e6ee; border-width: 2px;">
				<b-card-body class="p-0">
					<b-table hover :items="contacts" :fields="fields">
						<template #cell(number)="number">+{{ number.value }}</template>
						<template #cell(time)="time">{{ new Date(time.value.created * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</template>
					</b-table>
				</b-card-body>
			</b-card>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import HeaderButton from "@/components/Header/Button";

export default {
	name: "Contacts",
	components: { Header, Navbar, HeaderButton },

	data: () => ({
		fields: [
			{
				key: "id",
				label: "#",
				sortable: true,
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "name_first",
				label: "First Name",
			},
			{
				key: "name_last",
				label: "Last Name"
			},
			{
				key: "number",
				label: "Number",
				thClass: "text-center",
				tdClass: "text-center",
			},
			{
				key: "time",
				label: "Time",
				thClass: "text-center",
				tdClass: "text-center",
			}
		]
	}),

	mounted() {
		this.$store.dispatch("contacts/initialize")
	},

	computed: {

		contacts() {
			return this.$store.state.contacts.contacts
		}

	}

}
</script>
